import styled from "styled-components";
import { animated } from "react-spring";

export const AboutContainer = styled.div`
  width: 100%;
  padding: 40px 80px 0px 80px;
  @media (max-width: 850px) {
    display: none;
    width: 20px;
    padding: 20px 0px 0px 30px;
  }
`;

export const AboutHeader = styled.h2`
  font-size: 32px !important;
  font-weight: 300;
  margin: 0px 0px -4px 0px;
  color: black;
`;

export const AboutImage = styled.img`
  width: 50%;
  margin-top: 28px;
  @media (max-width: 850px) {
    width: 100%;
    margin-top: 45px;
    margin-bottom: 28px;
  }
`;

export const AboutBodyContainer = styled.div`
  width: 100%;
  padding: 0px 80px 0px 80px;
  display: flex;
  justify-content: center;
  @media (max-width: 850px) {
    padding: 0px 20px;
  }
`;

export const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 480px) {
    margin-bottom: 200px;
  }
`;

export const BottomImageLeft = styled.img`
  height: 200px;
  width: auto;
  @media only screen and (max-width: 480px) {
    height: 100px;
    margin-top: 20px;
  }
`;

export const BottomImageRight = styled.img`
  position: absolute;
  top: 30px;
  right: -100px;
  height: 300px;
  width: auto;
  margin-left: 0px;
  z-index: -1;
  @media only screen and (max-width: 480px) {
    top: 70px;
    right: 0px;
    height: 100px;
    margin-bottom: ${props => (props.isMobile ? "100px" : "0px")};
  }
`;

export const ContactContainer = styled.div`
  width: fit-content;
  margin: auto;
  height: auto;
  padding: 40px 250px 178px 250px;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 1030px) {
    padding: 40px 150px 178px 150px;
  }
  @media only screen and (max-width: 480px) {
    padding: 16px 55px 100px 55px;
    align-items: center;
  }
`;

export const ContactBlock = styled.div`
  width: 408px;
  height: 408px;
  border: 0.5px solid black;
  position: relative;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: white;
  margin: 20px;
  @media (max-width: 840px) {
    height: 330px;
    width: 330px;
    margin: 11px 0px;
  }
  @media (max-width: 480px) {
    height: 264px;
    width: 264px;
    margin: 11px 0px;
  }
`;

export const ContactBlockTitle = styled.p`
  font-size: 24px;
  font-weight: 300;
  text-align: left;
  margin: 3px 0px;
  color: black;
  width: 100%;
  margin-bottom: ${props => (props.last ? "16px !important" : "0px")};
  @media (max-width: 850px) {
    font-size: 20px;
    margin: 0px;
  }
`;

export const SocailTitle = styled.p`
  font-size: 20px;
  margin-bottom: 13px;
  color: black;
  @media (max-width: 850px) {
    font-size: 16px;
    margin-bottom: 0px;
  }
`;

export const QRBlock = styled.div`
  position: absolute;
  /* width: calc(100% - 32px); */
  width: calc(100% - 32px);
  height: 177px;
  display: flex;
  bottom: 20px;
  justify-content: center;
  @media only screen and (max-width: 840px) {
    height: fit-content;
    bottom: 60px;
  }
  @media (max-width: 850px) {
    width: calc(100% - 32px);
    height: fit-content;
    bottom: 10px;
    margin-bottom: 0px;
  }
`;

export const QRImage = styled.img`
  width: 177px;
  height: 177px;
  @media only screen and (max-width: 840px) {
    width: 140px;
    height: 140px;
  }
  @media only screen and (max-width: 480px) {
    width: 100px;
    height: 100px;
  }
`;

export const WeddingWrapper = styled.div`
  width: 100%;
  padding-top: 53px;
  height: calc(100vh);
  top: 100px;
  left: 0px;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    overflow-x: hidden;
  }
`;

export const WeddingContainer = styled.div`
  width: calc(100% - 317px);
  margin-left: 317px;
  @media only screen and (max-width: 850px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 185px;
    z-index: 99998;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    margin: 0px 0px 0px 0px;
    z-index: 99998;
  }
`;

export const LeftNav = styled.div`
  position: fixed;
  height: 100vh;
  width: 317px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 61px;
  border-right: 1px solid black;
  @media only screen and (max-width: 480px) {
    width: 100%;
    border-right: 0px;
  }
`;

export const WeddingInfoBlock = styled.div`
  position: relative;
  padding: 60px 0px 120px 0px;
  @media only screen and (max-width: 850px) {
    padding: 40px 0px 160px 0px;
  }
  @media only screen and (max-width: 480px) {
    padding: 10px 0px 120px 0px;
  }
`;

export const WeddingHeader = styled.p`
  font-size: 20px;
  margin: 0px;
`;

export const WeddingDesc = styled.p`
  width: 100%;
  font-size: 20px;
  margin-bottom: 20px;
  @media only screen and (max-width: 480px) {
    margin-top: 0px;
  }
`;

export const WeddingDescSpan = styled.span`
  width: 100%;
  font-size: 20px;
  @media only screen and (max-width: 480px) {
    margin-top: 50px;
  }
`;

export const BoothImage = styled.img`
  width: 100%;
  height: 662px;
  @media only screen and (max-width: 850px) {
    height: 154px;
    object-fit: cover;
    object-position: top;
  }
`;

export const WeddingMakeYourMemory = styled.img`
  position: absolute;
  bottom: -130px;
  left: 0px;
  width: 380px;
  @media only screen and (max-width: 850px) {
    bottom: -20px;
  }
  @media only screen and (max-width: 480px) {
    position: absolute;
    width: 217px;
    bottom: 15px;
    left: calc(50% - 108.5px);
  }
`;

export const WeddingLogo = styled.img`
  width: 183px;
  height: auto;
  @media only screen and (max-width: 480px) {
    width: 108px;
    margin-top: 8px;
  }
`;

export const Ul = styled.ul`
  list-style-type: none;
  margin-top: 62px;
  padding-left: 0px;
  @media only screen and (max-width: 850px) {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    justify-content: space-around;
    padding: 5px 10px;
    width: fit-content;
    margin-bottom: 0px;
    width: 100%;
    border-top: 0.5px solid black;
    border-bottom: 0.5px solid black;
  }
  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    justify-content: space-around;
    padding: 5px 10px;
    width: fit-content;
    margin-bottom: 0px;
    width: 100%;
    border-top: 0.5px solid black;
    border-bottom: 0.5px solid black;
  }
`;

export const Li = styled.li`
  cursor: pointer;
  font-family: "Futura Condensed";
  font-size: 20px;
  color: black;
  font-weight: 400;
  display: flex;
  align-items: center;
  height: 16px;
  margin: 0px 0px 22px 0px;
  @media only screen and (max-width: 850px) {
    font-size: 20px;
    margin: 10px 5px 10px 5px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
    margin: 10px 5px 10px 5px;
  }
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border: 0.5px solid black;
  margin-right: 16px;
  background-color: ${props => (props.selectedSection ? "black" : "white")};
  cursor: pointer;
`;

export const WeddingBlock = styled.div`
  padding: 0px 40px 10px 40px;
  height: 100%;
  border-right: 0.5px solid
    ${props => (props.borderColor ? props.borderColor : "black")};
  border-left: 0.5px solid
    ${props => (props.borderColor ? props.borderColor : "black")};
  border-top: 0px;
  border-left: 0px;
  margin: 0px;
  background-color: ${props => (props.bgColor ? props.bgColor : "white")};
  @media only screen and (max-width: 850px) {
    border-right: 0px;
  }
  @media only screen and (max-width: 480px) {
    height: 100%;
    border-right: 0px;
    padding-bottom: 150px;
    padding: 24px 24px 10px 24px;
    width: 100vw;
    border-bottom: ${props =>
      props.borderBottom ? "0.5px solid white" : "0px"};
  }
`;

export const BoothHeadeText = styled.p`
  font-size: 42px;
  font-weight: medium;
  margin-bottom: 30px;
`;

export const BoothHeader = styled.div`
  height: 100px;
`;

export const WeddingBoothImageContainer = styled.div`
  width: 100%;
  height: 380px;
  display: flex;
  justify-content: center;
`;

export const WeddingBoothImage = styled.img`
  height: 100%;
`;

export const WeddingBoothImageName = styled.p`
  font-size: 32px;
  margin: 0px;
`;

export const BackgroundImage = styled.img`
  position: fixed;
  top: -20vh;
  right: -10vw;
  z-index: -1;
  height: 120vh;
  width: auto;
  @media only screen and (max-width: 480px) {
    height: auto;
    top: 35vh;
    right: -35vw;
  }
`;

export const SectionHeader = styled.div`
  position: relative;
  width: 100%;
  height: 59px;
  border: 0px;
  border-bottom: 0.5px solid black;
  border-top: 0.5px solid black;
  padding: 16px 40.5px;
  @media only screen and (max-width: 480px) {
    padding: 16px 24px;
  }
`;

export const SectionHeaderText = styled.p`
  margin: 0px;
  font-size: 32px;
  font-weight: 300;
  color: black;
`;

export const SubmitRequestTitle = styled.div`
  position: relative;
  height: fit-content;
  width: fit-content;
  margin-bottom: 40px;
  margin-top: 27px;
  @media only screen and (max-width: 480px) {
    margin-top: 0px !important;
  }
`;

export const RequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RequestLabel = styled.p`
  font-size: 20px;
  width: 100%;
  margin: 0px;
  margin-bottom: 16px;
  font-weight: 300;
  color: black;
  text-transform: uppercase;
  @media only screen and (max-width: 480px) {
    font-size: 20px !important;
    margin-bottom: 21px;
  }
`;

export const WriteToUse = styled.p`
  font-size: 20px;
  width: 100%;
  margin: 0px;
  margin-bottom: 16px;
  font-weight: 300;
  color: black;
  text-transform: uppercase;
  @media only screen and (max-width: 480px) {
    font-size: 20px !important;
    margin-bottom: 0px;
  }
`;

export const UnderlineImage = styled.img`
  position: absolute;
  width: 100%;
  bottom: -25px;
  @media only screen and (max-width: 480px) {
    width: 100%;
    bottom: -20px;
  }
`;

export const StarImage = styled.img`
  position: absolute;
  width: 40px;
  top: -10px;
  right: -40px;
  @media only screen and (max-width: 480px) {
    width: 45px;
    top: -10px;
    right: -35px;
  }
`;

export const RequestInput = styled.input`
  width: ${props => (props.small ? "calc(100% - 30px)" : "100%")};
  border: 0px;
  border-bottom: ${props =>
    props.error ? "0.5px solid red" : "0.5px solid #7c7c7c"};
  margin-bottom: 48px;
  padding: 5px 5px 5px 0px;
  font-weight: 400;
  font-size: 16px;
  color: black;
  font-family: Futura;
  ::placeholder {
    color: ${props => (props.error ? "red" : "#7c7c7c")};
    opacity: 1;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const RequestTextarea = styled.textarea`
  width: 100%;
  height: 210px;
  border-radius: 0px;
  font-weight: 400;
  font-size: 16px;
  font-family: Futura;
  border: 0.5px solid #7c7c7c;
  @media only screen and (max-width: 480px) {
    font-size: 16px !important;
  }
`;

export const RequestSendButton = styled.div`
  width: 100%;
  height: 56px;
  background-color: ${props => (props.enable ? "black" : "#C4C4C4")};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: -8px;
  pointer-events: ${props => (props.enable ? "auto" : "none")};
  margin-top: 24px;
  :hover {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.25);
  }
`;

export const RequestSendButtonText = styled.p`
  font-size: 24px;
  color: white;
  text-align: center;
  margin: 0px;
  @media only screen and (max-width: 480px) {
    font-size: 20px;
  }
`;

export const Instructions = styled.div`
  width: 100%;
  min-height: 767px;
  /* height: auto; */
  padding: 32px 24px 32px 24px;
  background-color: black;
  @media only screen and (max-width: 480px) {
    min-height: 280px;
  }
`;

export const InstructionBlock = styled.div`
  width: 100%;
  height: auto;
  padding: 10px 0px;
  border-top: 0.5px solid white;
  position: relative;
  &:last-child {
    border-bottom: 0.5px solid white;
  }
`;

export const InstructionHeader = styled.h3`
  font-size: 24px;
  font-weight: 300;
  margin: 0px;
  color: white;
  cursor: pointer;
  @media only screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 20px;
    text-align: left;
  }
`;

export const InstructionDesc = styled.p`
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 14px;
  margin-top: 25px;
  color: white;
  letter-spacing: 0.03em;
  @media only screen and (max-width: 480px) {
    font-size: 18px;
    letter-spacing: 2%;
  }
`;

export const RadioText = styled.p`
  margin: 0px 30px -7px 0px;
  font-weight: ${props => (props.selectedBoothType ? 500 : 400)};
  font-family: Futura;
  font-size: 16px;
  color: ${props => (props.selectedBoothType ? "black" : "#7c7c7c")};
  cursor: pointer;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 40px;
  margin-top: 17px;
  @media only screen and (max-width: 480px) {
    margin-bottom: 40px;
  }
`;

export const Space = styled.div`
  height: 40px;
  @media only screen and (max-width: 480px) {
    height: 0px;
  }
`;

export const DisclaimerBlock = styled.div`
  padding: 51px 32px;
  height: 100%;
  border-top: 0px;
  border-left: 0px;
  @media only screen and (max-width: 480px) {
    border-right: 0px;
    padding-bottom: 150px;
    border-bottom: 0.5px solid black;
    padding: 24px 24px 10px 24px;
  }
`;

export const ShrinkableBlock = styled(animated.div)`
  width: 100%;
  overflow: hidden;
`;

export const DropdownImage = styled.img`
  position: absolute;
  top: 22px;
  right: 5px;
  cursor: pointer;
  @media only screen and (max-width: 480px) {
    top: 15px;
  }
`;

export const SampleBoothSection = styled.section`
  position: absolute;
  top: -53px;
  @media only screen and (max-width: 480px) {
    top: -190px;
  }
`;

export const SubmitRequestSection = styled.section`
  position: absolute;
  top: -53px;
  @media only screen and (max-width: 480px) {
    top: -190px;
  }
`;

export const InfoSection = styled.section`
  position: absolute;
  top: -53px;
  @media only screen and (max-width: 850px) {
  }
  @media only screen and (max-width: 480px) {
    top: -343px !important;
  }
`;

export const RelativeBox = styled.div`
  position: relative;
  height: fit-content;
  width: fit-content;
  margin-bottom: 30px;
  @media only screen and (max-width: 480px) {
    margin-bottom: 10px;
  }
`;

export const UnderlineImageContact = styled.img`
  position: absolute;
  bottom: -15px;
  height: auto;
  width: 100%;
  @media only screen and (max-width: 480px) {
    width: 100%;
    height: auto;
    bottom: -10px;
  }
`;

export const WhiteUnderline = styled.div`
  display: none;
  @media only screen and (max-width: 850px) {
    display: block;
    width: 100%;
    height: 0.5px;
    background-color: white;
  }
`;

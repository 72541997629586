import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 0px;
  border-bottom: ${props => (props.last ? "0px" : "0.5px solid white")};
  padding-bottom: 0px;
`;

const Header = styled.p`
  text-align: start;
  font-size: 12px;
  margin: 11px 0px -8px 0px;
  font-weight: 500;
  font-family: Futura;
  color: ${props => (props.color ? props.color : "white")};
`;

const Detail = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: end;
  font-size: 20px;
  margin: -10px 0px 16px 0px;
  font-weight: 300;
  color: ${props => (props.color ? props.color : "white")};
  @media only screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const BoothContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 3px;
  margin: 10px 12px;
`;

export const BoothDetail = ({ header, detail, color, last }) => {
  return (
    <>
      <Container last={last}>
        <Header color={color}>{header}</Header>
        {detail &&
          detail.map(item => (
            <Detail color={color}>
              {header === "DETAILS" && <Dot />}
              {item}
            </Detail>
          ))}
      </Container>
    </>
  );
};

import styled from "styled-components";

export const PlaceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  padding: 24px 0px 8px 0px;
  width: 100%;
  border-bottom: 0.5px solid white;
`;

export const BoothTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  padding: 24px 0px 8px 0px;
  width: 100%;
  border-bottom: 0.5px solid white;
  @media only screen and (max-width: 480px) {
    padding: 0px 0px 8px 0px;
  }
`;

export const PlaceProvince = styled.p`
  font-size: 15px;
  margin: 0px 0px 0px 0px;
  font-weight: 600;
  width: 100%;
  padding-bottom: 10px;
  color: black;
`;

export const PlaceName = styled.p`
  font-weight: 300;
  font-size: 20px;
  line-height: 0.9;
  margin: 0px 0px 5px 0px;
  color: black;
  font-weight: 400;
`;

export const SampleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  align-items: flex-end;
  padding: 0px 0px 0px 0px;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 32px 0px 32px;
  }
`;

export const SampleProvince = styled.p`
  font-size: 15px;
  color: white;
  margin: 0px 0px 5px 0px;
  font-weight: 600;
  width: 100%;
  padding-bottom: 0px;
  height: 17px;
  font-weight: 500;
  font-family: "Suisse Intl";
  @media only screen and (max-width: 480px) {
    padding-bottom: 10px;
  }
`;

export const SampleName = styled.p`
  font-size: 32px;
  min-height: 28px;
  line-height: 0.9;
  color: white;
  margin: 0px;
  font-weight: 300;
  margin-right: 5px;
  @media only screen and (max-width: 480px) {
    font-size: 45px;
    margin: 0px 0px 5px 0px;
  }
`;

export const SampleBoothName = styled.p`
  font-weight: 300;
  font-size: 32px;
  line-height: 0.9;
  margin: 0px 0px 5px 0px;
  color: white;
`;

export const FooterContainer = styled.div`
  height: fit-content;
  width: 100vw;
  margin-left: -317px;
  @media only screen and (max-width: 850px) {
    margin-left: 0px;
  }
`;

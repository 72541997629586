import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { graphql, StaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import scrollTo from "gatsby-plugin-smoothscroll";
import { useSpring } from "react-spring";
import emailjs, { init } from "emailjs-com";
import MoonLoader from "react-spinners/MoonLoader";

import "../utils/normalize.css";
import "../utils/css/screen.css";

import WeddingBoothMock from "../assets/wedding/weddingBoothMock.png";
import WeddingBoothMobileMock from "../assets/wedding/mobileheadingimage.png";
import MakeYourMemory from "../assets/wedding/makeYourMemory.png";
import SculptureLoveLogo from "../assets/wedding/sculpture-love-logo2.png";
import { MaterialCarousel } from "../components/MaterialCarousel";
import { BoothContainer, BoothDetail } from "../components/Wedding/BoothDetail";
import { ConfirmSendRequest } from "../components/ConfirmSendRequest";
import { FailedToSendRequest } from "../components/FailedToSendRequest";

import {
  WeddingContainer,
  WeddingDesc,
  WeddingWrapper,
  BoothImage,
  WeddingMakeYourMemory,
  WeddingLogo,
  WeddingBlock,
  Ul,
  Li,
  SectionHeader,
  SectionHeaderText,
  RequestContainer,
  RequestLabel,
  RequestInput,
  RequestTextarea,
  RequestSendButton,
  RequestSendButtonText,
  Instructions,
  InstructionBlock,
  InstructionHeader,
  InstructionDesc,
  Dot,
  RadioText,
  RadioContainer,
  Space,
  DisclaimerBlock,
  ShrinkableBlock,
  DropdownImage,
  SampleBoothSection,
  InfoSection,
  WeddingHeader,
  WeddingInfoBlock,
  WeddingDescSpan,
  UnderlineImage,
  StarImage,
  SubmitRequestTitle,
  WriteToUse,
  WhiteUnderline
} from "../components/Contact/Contact.style";

import {
  BoothTitleContainer,
  SampleBoothName,
  FooterContainer
} from "../components/Wedding/BoothDetail/BoothDetail";

import { Grid } from "@material-ui/core";
import Compact1 from "../assets/wedding/compact1.png";
import Compact2 from "../assets/wedding/compact2.png";
import Compact3 from "../assets/wedding/compact3.png";
import Standard1 from "../assets/wedding/standard1.png";
import Standard2 from "../assets/wedding/standard2.png";
import Standard3 from "../assets/wedding/standard3.png";
import { isMobile } from "react-device-detect";
import DownIcon from "../assets/wedding/down.svg";
import UpIcon from "../assets/wedding/up.svg";
import Underline from "../assets/wedding/underline.png";
import BlackUnderline from "../assets/wedding/blackunderline.png";
import Star from "../assets/wedding/star.svg";
import { Footer } from "../components/footer";
init("user_6D80ZzzIvA8VVUeLFc4nr");

const MockData1 = {
  detail: [
    {
      header: "SET UP DURATION",
      detail: ["2 hours"],
      key: "detail11"
    },
    {
      header: "PRICE",
      detail: ["Please contact thisissculpture@gmail.com"],
      key: "detail12"
    },
    {
      header: "DETAILS",
      detail: [
        "Starts at 4 hours",
        "Unlimited photos",
        "Can customize colors photo templates and booth color"
      ],
      key: "detail13"
    }
  ],
  sampleImages: [Compact1, Compact2, Compact3]
};

const MockData2 = {
  detail: [
    {
      header: "SET UP DURATION",
      detail: ["2 hours"],
      key: "detail21"
    },
    {
      header: "PRICE",
      detail: ["Please contact thisissculpture@gmail.com"],
      key: "detail22"
    },
    {
      header: "DETAILS",
      detail: [
        "Starts at 4 hours",
        "Unlimited photos",
        "Can customize colors photo templates and booth color"
      ],
      key: "detail23"
    }
  ],
  sampleImages: [Standard1, Standard2, Standard3]
};

let InstructionDetail = [
  {
    title: "WHEN WILL WE REPLY BACK?",
    desc:
      "As soon as we’ve received your request, we will try our best to get back to you!"
  },
  {
    title: "ARE THE BOOTHS ALWAYS AVAILABLE?",
    desc:
      "Please noted that other couples might share the same wedding date as yours, so some booths might not be available on the day you desire."
  },
  {
    title: "CAN I HAVE MY OWN BOOTH COLORS?",
    desc:
      "The booths appearance for Sculpture Weddings are fixed, to fit appropriately on your important day."
  },
  {
    title: "CAN I HAVE MY OWN FRAME DESIGNS?",
    desc:
      "Yes, you can! You can put your initials, names or designs on. Feel free to discuss with us on the frame designs as you wish."
  },
  {
    title: "HOW MANY PHOTOS WILL I GET? ",
    desc: "The photos are unlimited! You can take as much as you want!"
  }
];

const DropdownItem = ({
  number,
  title,
  style,
  desc,
  handleClick,
  shrinkState
}) => {
  return (
    <>
      <InstructionBlock>
        <DropdownImage
          src={shrinkState ? UpIcon : DownIcon}
          onClick={() => handleClick(number)}
        />
        <div
          style={{
            position: "relative",
            width: "fit-content"
          }}
        >
          <InstructionHeader onClick={() => handleClick(number)}>
            {title}
          </InstructionHeader>
          {shrinkState && (
            <img
              alt="underline"
              src={Underline}
              style={{
                position: "absolute",
                width: "100%",
                top: "30px"
              }}
            />
          )}
        </div>
        <ShrinkableBlock style={style}>
          <InstructionDesc>{desc}</InstructionDesc>
        </ShrinkableBlock>
      </InstructionBlock>
    </>
  );
};

const StyledStickyBox = styled.div`
  width: 317px;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-top: 28px;
  border-right: 0.5px solid #000000;
  height: calc(100vh - 53px);
  @media only screen and (max-width: 850px) {
    width: 100%;
    border: 0px;
    padding-top: 0px;
    background-color: white;
    z-index: 99999;
    height: fit-content;
  }
  @media only screen and (max-width: 480px) {
    position: sticky;
    top: 0px;
    width: 100%;
    border: 0px;
    padding-top: 0px;
    background-color: white;
    z-index: 99999;
    height: fit-content;
  }
`;

const IsMobileBlock = styled.div`
  display: block;
  width: 50%;
  @media only screen and (max-width: 850px) {
    display: none;
  }
`;

const IsNotMobileBlock = styled.div`
  display: none;
  @media only screen and (max-width: 850px) {
    display: block;
    width: 100%;
  }
`;

const WeddingPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const [isOpen, setIsOpen] = useState(false);
  const [failedIsOpen, setFailedIsOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState("info");
  const [error, setError] = useState({
    name: false,
    phone: false,
    email: false,
    date: false,
    boothType: false,
    additional: false
  });
  const [requestData, setRequestData] = useState({
    name: "",
    phone: "",
    email: "",
    date: "",
    boothType: "",
    additional: ""
  });
  const [shrinkState, setShrinkState] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false
  });
  const [loading, setLoading] = useState(false);

  const infoRef = useRef();
  const submitRequestRef = useRef();
  const boothSamplesRef = useRef();

  const springStyles0 = useSpring({
    height: shrinkState[0] ? 100 : 0
  });
  const springStyles1 = useSpring({
    height: shrinkState[1] ? 130 : 0
  });
  const springStyles2 = useSpring({
    height: shrinkState[2] ? 100 : 0
  });
  const springStyles3 = useSpring({
    height: shrinkState[3] ? 100 : 0
  });
  const springStyles4 = useSpring({
    height: shrinkState[4] ? 100 : 0
  });

  const handleRequestClick = (data, type) => {
    setRequestData(prev => {
      let temp = prev;
      temp[type] = data;
      return { ...prev, temp };
    });
  };

  const sendRequest = () => {
    if (
      requestData.name === "" ||
      requestData.phone === "" ||
      requestData.email === "" ||
      requestData.date === "" ||
      requestData.boothType === ""
    ) {
      setError(prev => {
        let temp = prev;
        temp["name"] = requestData.name === "";
        temp["phone"] = requestData.phone === "";
        temp["email"] = requestData.email === "";
        temp["date"] = requestData.date === "";
        temp["boothType"] = requestData.boothType === "";
        return { ...prev, temp };
      });
      setError(true);
    } else {
      setLoading(true);
      emailjs
        .send(
          "sculpturebangkok",
          "template_7fm3ak9",
          {
            name: "Request for sculpture wedding from " + requestData["name"],
            email: requestData["email"],
            phoneNumber: requestData["phone"],
            date: requestData["date"],
            boothType: requestData["boothType"],
            message: requestData["additional"]
          },
          "user_6D80ZzzIvA8VVUeLFc4nr"
        )
        .then(
          result => {
            setError(false);
            setIsOpen(true);
            setLoading(false);
          },
          error => {
            setError(false);
            setFailedIsOpen(true);
            setLoading(false);
          }
        );
    }
  };

  function closeModal(check) {
    if (check) {
      setRequestData({
        name: "",
        phone: "",
        email: "",
        date: "",
        boothType: "",
        additional: ""
      });
    }
    setIsOpen(false);
    setFailedIsOpen(false);
  }

  const handleClickSection = section => {
    scrollTo(`#${section}`);
    setSelectedSection(section);
  };

  const handleClick = number => {
    setShrinkState(prev => {
      let temPrev = { ...prev };
      temPrev[number] = !temPrev[number];
      return temPrev;
    });
  };

  const handleScroll = () => {
    if (infoRef.current.getBoundingClientRect().y >= -10) {
      setSelectedSection("info");
    }
    if (
      boothSamplesRef.current.getBoundingClientRect().y <= 0 &&
      selectedSection !== "boothsamples"
    ) {
      setSelectedSection("boothsamples");
    }
    if (
      submitRequestRef.current.getBoundingClientRect().y <= 0 &&
      selectedSection !== "submitrequest"
    ) {
      setSelectedSection("submitrequest");
    }
  };

  useEffect(() => {
    document.querySelector("#test").addEventListener("scroll", () => {
      handleScroll();
    });
    scrollTo(`#info`);
    return () => {
      document
        .querySelector("#test")
        .removeEventListener("scroll", handleScroll);
    };
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout title={siteTitle} siteBgColor={"white"}>
        <SEO
          title="Locations"
          keywords={[`wedding`, `sculpture`, `งานแต่งงาน`, `ตู้sculpture`]}
        />
        <WeddingWrapper id="test">
          <StyledStickyBox>
            <WeddingLogo src={SculptureLoveLogo} />
            <Ul>
              <Li onClick={() => handleClickSection("info")}>
                <Dot selectedSection={selectedSection === "info"} />
                INFO
              </Li>
              <Li onClick={() => handleClickSection("boothsamples")}>
                <Dot selectedSection={selectedSection === "boothsamples"} />
                BOOTH SAMPLES
              </Li>
              <Li onClick={() => handleClickSection("submitrequest")}>
                <Dot selectedSection={selectedSection === "submitrequest"} />
                SUBMIT REQUEST
              </Li>
            </Ul>
          </StyledStickyBox>
          <WeddingContainer>
            <Grid container spacing={0}>
              <IsNotMobileBlock>
                <Grid item lg={6} md={12} sm={12}>
                  <BoothImage src={WeddingBoothMobileMock} />
                </Grid>
              </IsNotMobileBlock>

              <Grid item lg={6} md={6} sm={12} style={{ position: "relative" }}>
                <InfoSection id="info" ref={infoRef} />
                <WeddingBlock>
                  <WeddingInfoBlock>
                    <WeddingHeader>
                      SCULPTURE LOVE
                      <WeddingDescSpan style={{ marginLeft: "5px" }}>
                        {" "}
                        is a service that helps making your wedding day even
                        more memorable.
                      </WeddingDescSpan>
                    </WeddingHeader>
                    <br />
                    <WeddingDesc style={{ marginBottom: "-5px" }}>
                      We aim to make your memories become a form.
                    </WeddingDesc>
                    <img
                      style={{ width: "60%", marginBottom: "30px" }}
                      src={BlackUnderline}
                      alt="blackunderline"
                    />
                    <WeddingHeader>
                      We offer two types of photobooths:
                    </WeddingHeader>
                    <WeddingDesc>
                      1. Compact
                      <br /> 2. Standard
                    </WeddingDesc>
                    <WeddingDesc>
                      See samples below and feel free to reach out to us for any
                      questions!
                    </WeddingDesc>
                    <WeddingMakeYourMemory src={MakeYourMemory} />
                  </WeddingInfoBlock>
                </WeddingBlock>
              </Grid>
              <IsMobileBlock>
                <Grid item lg={12} md={12} sm={12}>
                  <BoothImage src={WeddingBoothMock} />
                </Grid>
              </IsMobileBlock>
              <SectionHeader>
                <SectionHeaderText>BOOTH SAMPLES</SectionHeaderText>
                <SampleBoothSection id="boothsamples" ref={boothSamplesRef} />
              </SectionHeader>
              {/* <ShrinkableBlock style={springBoothSampleStyles}> */}
              <Grid container spacing={0}>
                <Grid item lg={6} md={6} sm={12}>
                  <WeddingBlock
                    bgColor={"black"}
                    borderColor={"white"}
                    borderBottom={false}
                  >
                    <BoothTitleContainer>
                      <SampleBoothName>1. COMPACT</SampleBoothName>
                    </BoothTitleContainer>
                    <BoothContainer>
                      {MockData1.detail.map((eachDetail, index) => {
                        return (
                          <>
                            <BoothDetail
                              detail={eachDetail.detail}
                              header={eachDetail.header}
                              key={eachDetail.key}
                              color={"white"}
                              last={MockData1.detail.length === index + 1}
                            />
                          </>
                        );
                      })}
                      <MaterialCarousel
                        sampleImages={MockData1.sampleImages}
                        color={"white"}
                      />
                    </BoothContainer>
                  </WeddingBlock>
                </Grid>
                <WhiteUnderline />
                <Grid item lg={6} md={6} sm={12}>
                  <WeddingBlock bgColor={"black"}>
                    <BoothTitleContainer>
                      <SampleBoothName>2. STANDARD</SampleBoothName>
                    </BoothTitleContainer>
                    <BoothContainer>
                      {MockData2.detail.map((eachDetail, index) => {
                        return (
                          <BoothDetail
                            detail={eachDetail.detail}
                            header={eachDetail.header}
                            key={eachDetail.key}
                            color={"white"}
                            last={MockData2.detail.length === index + 1}
                          />
                        );
                      })}
                      <MaterialCarousel
                        sampleImages={MockData2.sampleImages}
                        color={"white"}
                      />
                    </BoothContainer>
                  </WeddingBlock>
                </Grid>
              </Grid>
              <SectionHeader>
                <SectionHeaderText>SUBMIT REQUEST</SectionHeaderText>
                <SampleBoothSection
                  id={"submitrequest"}
                  ref={submitRequestRef}
                />
              </SectionHeader>
              <Grid container spacing={0} style={{ paddingBottom: "40px" }}>
                <Grid item lg={6} md={6} sm={12}>
                  <WeddingBlock
                    style={{ borderRight: "0px", borderBottom: "0px" }}
                  >
                    <RequestContainer>
                      <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <SubmitRequestTitle>
                            <WriteToUse>
                              WRITE TO US AND WE WILL CONTACT BACK TO YOU!
                            </WriteToUse>
                            <UnderlineImage
                              src={BlackUnderline}
                              alt="blackunderline"
                            />
                            <StarImage src={Star} alt="request" />
                          </SubmitRequestTitle>

                          <RequestLabel>Your name*</RequestLabel>
                          <RequestInput
                            error={error["name"]}
                            placeholder={"Enter your name"}
                            value={requestData["name"]}
                            onChange={e =>
                              handleRequestClick(e.target.value, "name")
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <RequestLabel>Your contact details*</RequestLabel>
                          <RequestInput
                            error={error["phone"]}
                            placeholder={"Enter your number"}
                            value={requestData["phone"]}
                            onChange={e =>
                              handleRequestClick(e.target.value, "phone")
                            }
                            small={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <Space />
                          <RequestInput
                            error={error["email"]}
                            placeholder={"Enter your email"}
                            value={requestData["email"]}
                            onChange={e =>
                              handleRequestClick(e.target.value, "email")
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <RequestLabel>When is your wedding day?</RequestLabel>
                          <RequestInput
                            error={error["date"]}
                            placeholder={"Enter your wedding day"}
                            value={requestData["date"]}
                            onChange={e =>
                              handleRequestClick(e.target.value, "date")
                            }
                            small={true}
                          />
                        </Grid>
                        {!isMobile && <Grid item xs={12} sm={12} md={6} />}
                        <Grid item xs={12} sm={12} md={12}>
                          <RequestLabel>Which booth do you want?*</RequestLabel>
                          <RadioContainer>
                            <Dot
                              selectedSection={
                                requestData["boothType"] === "compact"
                              }
                              onClick={() =>
                                handleRequestClick("compact", "boothType")
                              }
                            />
                            <RadioText
                              selectedBoothType={
                                requestData["boothType"] === "compact"
                              }
                              onClick={() =>
                                handleRequestClick("compact", "boothType")
                              }
                            >
                              Compact
                            </RadioText>
                            <Dot
                              selectedSection={
                                requestData["boothType"] === "standard"
                              }
                              onClick={() =>
                                handleRequestClick("standard", "boothType")
                              }
                            />
                            <RadioText
                              selectedBoothType={
                                requestData["boothType"] === "standard"
                              }
                              onClick={() =>
                                handleRequestClick("standard", "boothType")
                              }
                            >
                              Standard
                            </RadioText>
                          </RadioContainer>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <RequestLabel>WHAT ELSE DO YOU WANT?: </RequestLabel>
                          <RequestTextarea
                            placeholder={"Enter your special requirements!"}
                            value={requestData["additional"]}
                            onChange={e =>
                              handleRequestClick(e.target.value, "additional")
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          style={{
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <RequestSendButton
                            enable={
                              requestData["name"] !== "" &&
                              requestData["phone"] !== "" &&
                              requestData["email"] !== "" &&
                              requestData["date"] !== "" &&
                              requestData["boothType"] !== "" &&
                              !loading
                            }
                            onClick={() => sendRequest()}
                          >
                            {loading ? (
                              <MoonLoader size={20} />
                            ) : (
                              <RequestSendButtonText>
                                Submit request
                              </RequestSendButtonText>
                            )}
                          </RequestSendButton>
                        </Grid>
                      </Grid>
                    </RequestContainer>
                  </WeddingBlock>
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                  <DisclaimerBlock>
                    <Instructions>
                      <DropdownItem
                        number={0}
                        title={InstructionDetail[0].title}
                        style={springStyles0}
                        desc={InstructionDetail[0].desc}
                        handleClick={handleClick}
                        shrinkState={shrinkState[0]}
                      />
                      <DropdownItem
                        number={1}
                        title={InstructionDetail[1].title}
                        style={springStyles1}
                        desc={InstructionDetail[1].desc}
                        handleClick={handleClick}
                        shrinkState={shrinkState[1]}
                      />
                      <DropdownItem
                        number={2}
                        title={InstructionDetail[2].title}
                        style={springStyles2}
                        desc={InstructionDetail[2].desc}
                        handleClick={handleClick}
                        shrinkState={shrinkState[2]}
                      />
                      <DropdownItem
                        number={3}
                        title={InstructionDetail[3].title}
                        style={springStyles3}
                        desc={InstructionDetail[3].desc}
                        handleClick={handleClick}
                        shrinkState={shrinkState[3]}
                      />
                      <DropdownItem
                        number={4}
                        title={InstructionDetail[4].title}
                        style={springStyles4}
                        desc={InstructionDetail[4].desc}
                        handleClick={handleClick}
                        shrinkState={shrinkState[4]}
                      />
                    </Instructions>
                  </DisclaimerBlock>
                </Grid>
              </Grid>
              <FooterContainer>
                <Footer />
              </FooterContainer>
            </Grid>
          </WeddingContainer>
        </WeddingWrapper>

        <ConfirmSendRequest isOpen={isOpen} closeModal={closeModal} />
        <FailedToSendRequest isOpen={failedIsOpen} closeModal={closeModal} />
      </Layout>
    </>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <WeddingPage location={props.location} data={data} {...props} />
    )}
  />
);
